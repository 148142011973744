import { JSX } from 'react';
import { Col, Image, Row } from 'antd';
import ProductSwitcher, { ProductKeys } from './productSwitcher';
import { SupportDropdown } from './supportDropdown';
import { UserDropdown } from './userDropdown';
import { Config } from '../../../../config/index';
import { handleUserLogout } from '../../../../utils/index';
import { IOrganizationMember } from '../../../../store/models/redux/index';
import { useActiveMember } from '../../../../hooks/organizationMember/index';
import isEmpty from 'lodash/isEmpty';
import { OrganizationSwitcher } from './organizationSwitcher';
import { Notifications } from './notificationDropdown';
import { useFeatureToggle } from '@copilot/common/hooks/feature';
import { Features } from '@copilot/data/responses/interface';
import { useOrganizationLogo } from '@copilot/common/hooks/organization';
import { LinkedInStatusIndicator } from './linkedInStatusIndicator';

/**
 * [Smart Component] Smart container for the Prospecting Tool's top navigation bar
 */
export function ProspectingToolHeaderContainer(): JSX.Element {
	const activeMember = useActiveMember();
	const logoPath = useOrganizationLogo();
	const isAccountDashboardEnabled = useFeatureToggle(Features.AccountDashboardFeature);

	const onOpenHelpPortal = () => window.open(Config.helpPortalURL);

	const onOpenSiteStatus = Config.siteStatusURL
		? () => window.open(Config.siteStatusURL)
		: undefined;

	const onLogout = () => handleUserLogout(Config.isB2CEnabled);

	return (
		<ProspectingToolHeader
			member={activeMember}
			onOpenHelpPortal={onOpenHelpPortal}
			onOpenSiteStatus={onOpenSiteStatus}
			onLogout={onLogout}
			logoPath={logoPath}
			showProductSwitcher={isAccountDashboardEnabled}
		/>
	);
}

type ProspectingToolHeaderProps = Readonly<{
	/**
	 * The current active member
	 */
	member: IOrganizationMember | null;
	/**
	 * The path to the logo to display in the header
	 */
	logoPath?: string;
	/**
	 * Callback for clicking to open a help portal
	 */
	onOpenHelpPortal: () => void;
	/**
	 * Callback for clicking to open site status
	 */
	onOpenSiteStatus?: () => void;
	/**
	 * Callback for logging out the current user
	 */
	onLogout: () => void;
	/**
	 * Whether to show the product switcher
	 */
	showProductSwitcher: boolean;
}>;

function ProspectingToolHeader(props: ProspectingToolHeaderProps): JSX.Element {
	const {
		member,
		logoPath,
		onOpenHelpPortal,
		onOpenSiteStatus,
		onLogout,
		showProductSwitcher,
	} = props;

	return (
		<Row justify="space-between">
			<Col>
				{!isEmpty(logoPath) && (
					<Image
						style={{ maxHeight: 40, width: 'auto' }}
						src={logoPath}
						preview={false}
					/>
				)}
				{showProductSwitcher && (
					<ProductSwitcher currentProductKey={ProductKeys.teamsAccount} isDark />
				)}
			</Col>
			<Col style={{ display: 'flex' }}>
				<LinkedInStatusIndicator currentMember={member} />
				<SupportDropdown
					handleOpenHelpPortal={onOpenHelpPortal}
					handleOpenSiteStatus={onOpenSiteStatus}
					isDark
				/>
				<OrganizationSwitcher isDark />
				<Notifications isDark />
				<UserDropdown name={member?.name ?? ''} handleLogout={onLogout} isDark />
			</Col>
		</Row>
	);
}
